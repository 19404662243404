import React from 'react';
import Layout from '../utils/layout';
import Landing from '../components/scenes/Landing/Landing';
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const LandingPage = compose(
  withEmailVerification,
  withAuthorization(() => true), // not restricted
)(Landing);

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
);
