import React, { Component } from 'react';

class Landing extends Component {
  render() {
    return (
      <div className="landing container">
        <h1>Головна</h1>
        <p>Тут буде красива стаття з високим рівнем конверсії.</p>
      </div>
    );
  }
}

export default Landing;
